import "../css/app.css";
import Alpine from 'alpinejs';
import 'lazysizes';
import {DateTime} from "luxon";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

// HMR
if(import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// Setup Alpine.js
window.Alpine = Alpine
Alpine.plugin(focus);
Alpine.start();

// flatpickr
flatpickr("#startAt", {
    enableTime: true,
    disableMobile: true,
    dateFormat: 'Z',
    altInput: true,
    altFormat: "d/m/Y h:i K",
    parseDate: (date: string, format: string) => {
        return DateTime.fromISO(date,{zone: "utc"}).toLocal().toJSDate();
    },
});

flatpickr("#finishAt", {
    enableTime: true,
    disableMobile: true,
    dateFormat: 'Z',
    altInput: true,
    altFormat: "d/m/Y h:i K",
    parseDate: (date:string, format:string) => {
        return DateTime.fromISO(date,{zone: "utc"}).toLocal().toJSDate();
    },
});